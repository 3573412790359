import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import {
  getDataHandlerAPI,
  getParticularObjectDataHanndler,
} from "src/apiConfig/service";
import { calculateTimeLeft } from "../utils/index";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem("token", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    sessionStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.sessionStorage.getItem("token");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData, setUserData] = useState({});
  const [connectedExchangeList, setConnectedExchangeList] = useState([]);
  const [userWalletBalance, setUserWalletBalance] = useState({});
  const [endTime, setEndtime] = useState();
  const [timeLeft, setTimeLeft] = useState();



  const getUserProfileDatahandler = async (token) => {
    try {
    
      const response = await getDataHandlerAPI("getProfile", token);
      if (response) {
        setUserData(response?.data?.result);
        setUserWalletBalance(response?.data?.result);
        console.log("refresh login")
       
      }
      
    } catch (error) {
      
      console.log(error);
    }
   
  };
  const getUserWalletBalance = async () => {
    if (window.sessionStorage.getItem("token")) {
      try {
        const response = await getDataHandlerAPI(
          "transferBalance",
          window.sessionStorage.getItem("token")
        );
        if (response) {
          setUserWalletBalance(response.data.result);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("token")) {
      const interval = setInterval(
        () => getUserProfileDatahandler(window.sessionStorage.getItem("token")),
        200000
      );
      return () => {
        clearInterval(interval);
      };
    }
  }, []);
  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime * 1000));
      }, 5000);
      return () => clearTimeout(timer);
    }
  });
  useEffect(() => {
    if (window.sessionStorage.getItem("token")) {
      getUserProfileDatahandler(window.sessionStorage.getItem("token"));
    }
  }, []);

  let data = {
    userLoggedIn: isLogin,
    userData,
    timeLeft,
    userWalletBalance,
    setTimeLeft,
    setEndtime,
    connectedExchangeList,
    setIsLogin: (data) => setIsLogin(data),
    checkLogin: (data) => checkLogin(data),
    getUserProfileDatahandler: (data) => getUserProfileDatahandler(data),

    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
