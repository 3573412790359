export const baseurl = "https://node.bitechchain.com/";

// export const baseurl = "https://node-biworld.mobiloitte.org/";

// export const baseurl = "http://182.72.203.250:1947/";
// export const baseurl = "http://172.16.1.132:3039/"; // local

let base = `${baseurl}api/v1`;
let user = `${base}/user`;
let admin = `${base}/admin`;
let packages = `${base}/package`;
let wallet = `${base}/wallet`;
let stack = `${base}/stack`;
let statics = `${base}/static`;

const Apiconfigs = {
  login: `${user}/login`,
  applyKyc: `${user}/applyKyc`,
  getProfile: `${user}/viewProfile`,
  forgotPassword: `${user}/forgotPassword`,
  verifyOTP: `${user}/verifyLink`,
  registration: `${user}/registration`,
  resetPassword: `${user}/resetPassword`,
  editProfile: `${user}/editProfile`,
  changePassword: `${user}/changePassword`,
  listDepartment: `${user}/listDepartment`,
  raiseAticket: `${user}/raiseAticket`,
  ticketinbox: `${user}/inbox`,
  viewInbox: `${user}/viewInbox`,
  myDirects: `${user}/myDirects`,
  generateQr: `${user}/generateQr`,
  enableTwoFa: `${user}/enableTwoFa`,
  viewInbox: `${user}/viewInbox`,
  myDirects: `${user}/myDirects`,
  disableTwoFa: `${user}/disableTwoFa`,
  verifyTwoFa: `${user}/verifyTwoFa`,
  listUserTransaction: `${user}/listUserTransaction`,
  dashboard: `${user}/dashboard`,
  level: `${user}/level`,
  addThread: `${user}/addThread`,
  transferBalance: `${user}/transferBalance`,
  unilevelTree: `${user}/unilevelTree`,
  dailyEarningHistory: `${user}/dailyEarningHistory`,
  withdrawAmount: `${user}/withdrawAmount`,
  sendBit: `${user}/sendBit`,
  totalLevel: `${user}/totalLevel`,
  sendReceive: `${user}/sendReceive`,
  verifyWithdraw: `${user}/verifyWithdraw`,
  verifySend: `${user}/verifySend`,
  resendWithdrawOtp: `${user}/resendWithdrawOtp`,
  resendSendOtp: `${user}/resendSendOtp`,
  claimAmount: `${user}/claimAmount`,
  depositBalance: `${user}/depositBalance`,
  directsTree: `${user}/directsTree`,
  directAndTeam: `${user}/directAndTeam`,

  //admin
  uploadFile: `${admin}/uploadFile`,

  //package
  listPackages: `${packages}/listPackages`,
  viewPackage: `${packages}/viewPackage`,

  //wallet
  getBalance: `${wallet}/getBalance`,
  depositToken: `${wallet}/depositToken`,

  //stake
  stacking: `${stack}/stacking`,
  packageDetails: `${stack}/packageDetails`,
  sendOtp: `${stack}/sendOtp`,
  verifyOtp: `${stack}/verifyOtp`,
  purchaseHistoryApi: `${stack}/purchaseHistoryApi`,
  stakehistory: `${stack}/stakehistory`,

  //static
  userQuery: `${statics}/userQuery`,
  subscribeNewsletter: `${statics}/subscribeNewsletter`,
  staticContentList: `${statics}/staticContentList`,
  faqList: `${statics}/faqList`,
};
export default Apiconfigs;
