import React from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  ListItem,
  List,
  Link,
  IconButton,
} from "@material-ui/core";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import Scroll from "react-scroll";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { AiOutlineTwitter } from "react-icons/ai";
// import { FaFacebookF } from "react-icons/fa";
import { AiOutlineInstagram } from "react-icons/ai";
const ScrollLink = Scroll.Link;

const useStyles = makeStyles((theme) => ({
  footerSection: {
    overflow: "hidden",
    backgroundSize: "cover",
    backgroundImage: "url(images/footerimage.png)",
    backgroundPosition: "center",
    position: "relative",
    padding: "80px 0px 0",
    zIndex: "2",
    [theme.breakpoints.only("xs")]: {
      padding: "20px 0px 0",
    },

    "& .bottomText": {
      textAlign: "center",
      fontSize:"9px !important",
    },
    "& .copy": {
      borderTop: "1px solid #202559",
      padding: "42px 0",
      textAlign: "left",
      fontWeight: 300,
      fontSize: "12px",
      color: "#626262",

      [theme.breakpoints.down("xs")]: {
        display: "block",
        padding: "19px 0",
        marginTop: "10px !important",
      },
    },

    "& ul": {
      paddingLeft: "0",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "30px",
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: "0px",
      },
      "& li": {
        paddingLeft: "0",
        alignItems: "center",
        color: " #1D1D1D",
        fontSize: "14px",
        "& svg": {
          marginRight: "10px",
          color: "#408FAC",
          fontSize: "15px",
        },
      },
    },
    "& svg": {
      color: "#408FAC",
      fontSize: "15px",
    },
    "& p": {
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px !important",
        maxWidth: "100%",
      },
    },
    "& h6": {
      fontSize: "14px",
      fontWeight: "700",
      textTransform: "uppercase",
      background: "linear-gradient(180deg, #FDA645 0%, #FF00CD 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      [theme.breakpoints.down("sm")]: {
        marginTop: "30px",
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: "20px",
        fontSize: "14px !important",
      },
    },
    "& a": {
      color: "#A6ACD6",
      display: "flex",
      fontSize: "14px",
      alignItems: "center",
      fontWeight: "300",
      fontFamily: "'Inter', sans-serif",
      textDecoration: "none",
      lineHeight: "28px",
      "&:hover": {
        color: "#9b05a8",
        textDecoration: "none",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
        lineHeight: "10px",
      },
    },
  },
  socialIcons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& button": {
      borderRadius: "50%",
      width: "40px",
      height: "40px",
      display: "flex",
      border: "1px solid #522262",
      alignItems: "center",
      justifyContent: "center",
      background: "#111430",
      "& svg": {
        color: "#fff",
      },

      "&:hover": {
        border: "1px solid #9b05a8 !important",
        color: "#9b05a8",
        "& svg": {
          color: "#9b05a8",
        },
      },

    },
  },
  supportBox: {
    display: "flex",
    justifyContent: "right",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "left",
    },
  },
  orderSet1: {
    [theme.breakpoints.down("xs")]: {
      order: 1,
    },
  },
  orderSet2: {
    [theme.breakpoints.down("xs")]: {
      order: 5,
    },
  },
  orderSet3: {
    [theme.breakpoints.down("xs")]: {
      order: 2,
    },
  },
  orderSet4: {
    [theme.breakpoints.down("xs")]: {
      order: 3,
    },
  },
  orderSet5: {
    [theme.breakpoints.down("xs")]: {
      order: 4,
    },
  },
  backgroundimage2: {
    position: "absolute",
    bottom: " 0",
    maxWidth: "500px",
    zIndex: "-1",
    left: "8%",
    top: "40%",
    opacity: "0.2",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  backgroundimage1: {
    position: "absolute",
    maxWidth: "394px",
    left: " 70.51%",
    right: " 1.34%",
    top: " -9.07%",
    bottom: "87.91%",
    opacity: " 0.1",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  about: {
    paddingLeft: "60px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <>
      <Box className={classes.footerSection}>
        <img
          src="images/imagebg1.png"
          className={classes.backgroundimage1}
          width="100%"
        />
        <img
          src="images/imagebg2.png"
          className={classes.backgroundimage2}
          width="100%"
        />

        <Container maxWidth="lg">
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              className={classes.orderSet1}
            >
              <Box>
                <Box mb={2}>
                  <RouterLink to="/">
                    <img src="images/Logo.png" style={{ maxWidth: "150px" }} />{" "}
                    <br />
                  </RouterLink>
                </Box>
                <Typography variant="body2">
                  Bitechchain believes in connecting the world with the power of
                  the blockchain Internet of Things.
                </Typography>

                <Box mt={4} ml={-2} display="flex" alignItems="center">
                  <Box className={classes.socialIcons} ml={2}>
                    <IconButton
                      onClick={() => window.open("https://www.facebook.com/Biworldio-116519281036450")}
                    >
                      <img
                        src="images/icon4.png"
                        onMouseOver={(e) => (e.currentTarget.src = "images/iconcolor4.png")}
                        onMouseOut={(e) => (e.currentTarget.src = "images/icon4.png")}

                      />
                    </IconButton>
                  </Box>
                  &nbsp; &nbsp;
                  <Box className={classes.socialIcons}>
                    <IconButton
                      onClick={() => window.open("https://in.pinterest.com/biworldio/_saved/")}
                    >
                      <img
                        src="images/icon3.png"
                        onMouseOver={(e) => (e.currentTarget.src = "images/iconcolor3.png")}
                        onMouseOut={(e) => (e.currentTarget.src = "images/icon3.png")}

                      />

                    </IconButton>
                  </Box>
                  &nbsp; &nbsp;
                  <Box className={classes.socialIcons}>
                    <IconButton
                      onClick={() => window.open(" https://twitter.com/biworldio")}
                    >
                      <img
                        src="images/icon2.png"
                        onMouseOver={(e) => (e.currentTarget.src = "images/iconcolor2.png")}
                        onMouseOut={(e) => (e.currentTarget.src = "images/icon2.png")}

                      />
                    </IconButton>
                  </Box>
                  &nbsp; &nbsp;
                  <Box className={classes.socialIcons}>
                    <IconButton
                      onClick={() => window.open("https://www.instagram.com/accounts/login/?next=/biworldio/")}
                    >
                      <img
                        src="images/icon1.png"
                        onMouseOver={(e) => (e.currentTarget.src = "images/iconcolor1.png")}
                        onMouseOut={(e) => (e.currentTarget.src = "images/icon1.png")}
                        width="22px"
                      />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid
              item
              xs={6}
              sm={4}
              md={3}
              lg={3}
              className={classes.orderSet3}
            >
              <Box className={classes.about}>
                <Typography variant="h6">Product</Typography>
                <List>
                  <ListItem to="/blockchain" component={RouterLink}>
                    Blockchain
                  </ListItem>
                  <ListItem to="/" component={RouterLink}>
                    Dex
                  </ListItem>
                  <ListItem to="/social-network" component={RouterLink}>
                    Social Network
                  </ListItem>
                  <ListItem to="/gaming" component={RouterLink}>
                    Gaming
                  </ListItem>
                </List>
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              md={3}
              lg={3}
              className={classes.orderSet4}
            >
              <Typography variant="h6">About</Typography>

              <List>
                <ListItem to="/" component={RouterLink}>
                  Home
                </ListItem>
                <ListItem to="/about" component={RouterLink}>
                  About Us
                </ListItem>
                <ListItem to="/" component={RouterLink}>
                  Roadmap
                </ListItem>
                <ListItem to="/contactUs" component={RouterLink}>
                  Contact Us
                </ListItem>
              </List>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              md={3}
              lg={3}
              className={classes.orderSet4}
            >
              <Box>
                <Typography variant="h6">Support</Typography>
                <List>
                  {/* <ListItem to="/faq" component={RouterLink}>
                    FAQs
                  </ListItem> */}
                  <ListItem to="/term-and-condition" component={RouterLink}>
                    Terms & Conditions
                  </ListItem>
                  <ListItem to="/privacy" component={RouterLink}>
                    Privacy policy
                  </ListItem>
                  <ListItem to="/exchange" component={RouterLink}>
                    Exchange
                  </ListItem>
                </List>
              </Box>
            </Grid>
          </Grid>
          <Box
            className="copy"
            mt={4}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body2" className="bottomText">
              2022 © Bitechchain Group Limited Company. All Rights Reserved
            </Typography>

            <Typography variant="body2" className="bottomText">Lic. No. : 3851783561</Typography>
          </Box>
        </Container>
      </Box>
    </>
  );
}
