import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  Box,
  Container,
  Popper,
  List,
  ListItem,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { NavLink } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Logo from "./../../component/Logo";
import Scroll from "react-scroll";
import { AiOutlineCaretDown } from "react-icons/ai";
import { AuthContext } from "src/context/Auth";
const ScrollLink = Scroll.Link;
const useStyles = makeStyles((theme) => ({
  joinButton: {
    [theme.breakpoints.down("md")]: {},
  },
  labeltext: {
    padding: "0em 0em 0.7em",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "27px",
    color: "#fff",
    display: "block",
    whiteSpace: "nowrap",
    verticalAlign: "baseline",
    borderRadius: "0.25em",
  },
  dialoginputbox: {
    width: "393px",
    height: "35px",
    border: "2px solid #fff",
    paddingLeft: "10px",
    color: "#fff",
    backgroundColor: "#141414",
    borderRadius: "8px",
    // boxShadow: "2px 5px 2px #888888ab",

    "@media (max-width: 900px)": {
      width: "291px",
    },
  },
  launchButton: {
    [theme.breakpoints.down("xs")]: {
      padding: "10px 30px !important",
      marginTop: "9px",
    },
  },
  menuButton: {
    fontSize: "12px",
    fontWeight: "500",
    borderRadius: 0,
    minWidth: "auto",
    color: "#A6ACD6",
    height: "31px",
    padding: "0px 7px",
    letterSpacing: "1px",
    marginLeft: "15px",
    textDecoration: "none",
    lineHeight: "54px",
    "& span": {
      display: "flex",
      alignItems: "center",
    },

    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#FFF",
      padding: "12px 0px !important",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      height: "auto",
    },
    "&.activeMenu": {
      background: "linear-gradient(180deg, #FDA645 0%, #FF00CD 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
      textFillColor: "transparent",
      position: "relative",
      "&:before": {
        content: "' '",
        position: "absolute",
        top: "26px",
        left: " 0px",
        width: "100%",
        height: "5px",
        background:
          "radial-gradient(50% 50% at 50% 50%, #FDA645 0%, rgba(255, 0, 205, 0) 100%)",
        [theme.breakpoints.only("xs")]: {
          display: "none",
        },
      },
    },
    "&[tabIndex]:focus": {
      background: "linear-gradient(180deg, #FDA645 0%, #FF00CD 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
      textFillColor: "transparent",
    },
    "&[tabIndex]:active": {
      color: "#19AAE6",
    },
    "&:hover": {
      background: "linear-gradient(180deg, #FDA645 0%, #FF00CD 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
      textFillColor: "transparent",
    },
  },

  toolbar: {
    display: "flex",
    padding: "8px 0",
    justifyContent: "space-between",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
    },
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    paddingLeft: "10px",
    width: "200px",
    marginBottom: "15px",
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    background: "#0d0f1b",
    width: "260px",
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  drawericon: {
    color: "#000",
    position: "absolute",
    top: "8%",
    right: "0px",
    fontSize: "25px",
  },
  logoImg: {
    width: "75px",
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
    },
  },
  containerHeight: {
    height: "100%",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
    minHeight: "60px !important",
  },

  menuLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  inerrMenu: {
    display: "flex",
    listStyle: "none",
    padding: "0",
    whiteSpace: "nowrap",
    paddingLeft: "25px",
    alignItems: "center",
    // justifyContent: "flex-end",
    justifyContent: "flex-start",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      display: "block",
      padding: "0",
    },
    "& li": {
      "&.active": {
        background: "red",
      },
    },
  },

  menuul: {
    display: "flex",
    listStyle: "none",
    padding: "0",
    alignItems: "center",
    // margin: "0",
    height: "30px",
    justifyContent: "space-between",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      display: "block",
      "& .logoDesk": {
        display: "none",
      },
    },

    "& .buttonRound": {
      width: "41px",
      height: "41px",
      borderRadius: "50%",
      color: "#fff",
      marginLeft: "15px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "20px",
      cursor: "pointer",
      border: "2px solid #FFFFFF",
      [theme.breakpoints.down("md")]: {
        marginBottom: "20px",
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: "20px",
      },
      "&:hover": {
        background: "#fff",
        color: "#000",
        // border: "2px solid #000",
      },
    },
  },

  topBox: {},
}));

export default function Header({ buttonClick }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  //Popper
  const [anchorEl, setAnchorEl] = React.useState(null);
  const auth = useContext(AuthContext);
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const openpopper = Boolean(anchorEl);
  const id = openpopper ? "simple-popper" : undefined;
  //Popper end
  const handleClose = () => {
    setOpen(false);
  };
  const {
    toolbar,
    menuul,
    drawerContainer,
    drawericon,
    menuLeft,
    logoDrawer,
    containerHeight,
    inerrMenu,
    mainHeader,
    menuButton,
    launchButton,
  } = useStyles();
  const history = useHistory();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;
  const headercolor = window.location.href.split("/")[3];
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1280
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const displayDesktop = () => {
    return (
      <Container className="p-0">
        <Toolbar className={toolbar}>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            style={{ paddingLeft: "0px" }}
          >
            <Grid item xs={12} align="center">
              {menuText}
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <img
              className={logoDrawer}
              src="images/Logo.png"
              alt=""
              style={{ width: "200px" }}
            />

            {menuText}
            <div style={{ padding: "16px" }}>{connectBtn}</div>
          </div>
        </Drawer>

        <div>{productLogo}</div>

        <Grid container>
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <IconButton
              className={drawericon}
              {...{
                edge: "start",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                width="60px"
                height="60px"
                style={{ color: "rgb(241 55 163)", fontSize: "35px" }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const productLogo = (
    <Box display="flex" alignItems="center">
      <Link to="/" style={{ display: "inherit" }}>
        <img src="images/Logo.png" alt="BiWorld" className="LogoImg" />
      </Link>
    </Box>
  );

  const connectBtn = <Box></Box>;

  const menuText = (
    <nav>
      <ul class={menuul}>
        <Box className={menuLeft}>
          <div className="logoDesk">
            <ScrollLink
              className={menuButton}
              smooth={true}
              duration={500}
              to="section1"
            >
              {" "}
              {productLogo}
            </ScrollLink>
          </div>
        </Box>
        <li>
          <li className="activecolor">
            <ul className={inerrMenu}>
              <li>
                <NavLink
                  exact
                  activeClassName="activeMenu"
                  className={menuButton}
                  to="/about"
                >
                  {" "}
                  ABOUT US{" "}
                </NavLink>
              </li>
              <li>
                <Button
                  aria-describedby={id}
                  type="button"
                  className={menuButton}
                  onClick={handleClick}
                >
                  OUR PROJECTS&nbsp;
                  <AiOutlineCaretDown />
                </Button>
                <Popper id={id} open={openpopper} anchorEl={anchorEl}>
                  <div className="menuList">
                    <List>
                      <ListItem onClick={() => history.push("/blockchain")}>
                        Blockchain
                      </ListItem>
                      <ListItem onClick={() => history.push("/exchange")}>
                        Exchange
                      </ListItem>
                      <ListItem onClick={() => history.push("/social-network")}>
                        Social Network
                      </ListItem>
                      <ListItem onClick={() => history.push("/gaming")}>
                        Gaming
                      </ListItem>
                    </List>
                  </div>
                </Popper>
              </li>

              <li>
                <NavLink
                  exact
                  activeClassName="activeMenu"
                  className={menuButton}
                  to="/contactUs"
                >
                  {" "}
                  CONTACT US
                </NavLink>{" "}
              </li>
              {!window.sessionStorage.getItem("token") ? (
                <li>
                  {/* <NavLink
                    exact
                    activeClassName="activeMenu"
                    className={menuButton}
                    to="https://biworld.io/"
                  >
                    {" "}
                    LOGIN
                  </NavLink> */}
                  <a
                    activeClassName="activeMenu"
                    className={menuButton}
                    href="https://biworld.io/"
                    target="_blank"
                  >
                    {" "}
                    LOGIN
                  </a>
                </li>
              ) : (
                ""
              )}

              <li style={{ marginLeft: "15px" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  className={launchButton}
                  // onClick={(e) =>
                  //   window.sessionStorage.getItem("token")
                  //     ? history.push("/dashboard")
                  //     : history.push("/register")
                  // }
                  onClick={() => window.open("https://biworld.io/")}
                >
                  {/* {window.sessionStorage.getItem("token")
                    // ? "DASHBOARD"
                    // : 
                    "START NOW"} */}
                  START NOW
                </Button>
              </li>
            </ul>
          </li>
        </li>
      </ul>
    </nav>
  );

  return (
    <>
      <AppBar
        className="headerNav"
        style={
          headercolor == ""
            ? { backgroundColor: "transparent", border: "1px solid #000000" }
            : { backgroundColor: "transparent", border: "none" }
        }
        position="relative"
        elevation={0}
      >
        <Container maxWidth="fixed" className={containerHeight}>
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>
    </>
  );
}
