import shadows from "@material-ui/core/styles/shadows";

export default {
  h1: {
    fontWeight: 800,
    fontSize: 50,
    fontFamily: "'MonumentExtendedBold', sans-serif",
    "@media(max-width:1024px)": {
      fontSize: "34px !important",
    },
  
    // "@media(max-width:767px)": {
    //   fontSize: "25px !important",
    // },
  },
  h2: {
    fontSize: 40,
    fontWeight: 800,
   
    fontFamily: "'MonumentExtendedBold', sans-serif",
    "@media(max-width:1024px)": {
      fontSize: "27px !important",
    },
  
    "@media(max-width:767px)": {
      fontSize: "25px !important",
    },
  },
  h3: {
    fontWeight: 500,
    fontSize: 35,
  
    fontFamily: "'MonumentExtendedBold', sans-serif",
    "@media(max-width:605px)": {
      fontSize: "27px",
    },
  },
  h4: {
    fontWeight: 500,
    fontSize: 30,
    color: "#fff",
    fontFamily: "'MonumentExtendedBold', sans-serif",
    "@media(max-width:605px)": {
      fontSize: "24px",
    },
  },
  h5: {
    fontWeight: 700,
    fontSize: 24,
    fontFamily: "'Montserrat', sans-serif",
    "@media(max-width:605px)": {
      fontSize: "18px",
    },
  },
  h6: {
    fontWeight: 500,
    fontSize: 20,
    fontFamily: "'Montserrat', sans-serif",
    "@media(max-width:605px)": {
      fontSize: "16px",
    },
  },

  body1: {
    color: "#fff",
    fontSize: "12px",
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: "300",
    lineHeight: "25px",
    "@media(max-width:605px)": {
      fontSize: "10px !important",
      lineHeight: "17px",
    },
  },
  body2: {
    color: "#A6ACD6",
    fontSize: "15px",
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: "300",
    lineHeight: "24px",
    letterSpacing: "0.72px",
    "@media(max-width:605px)": {
      fontSize: "12px !important",
      lineHeight: "18px !important",
    },
  },

  overline: {
    fontWeight: 500,
  },
  button: {
    textTransform: "capitalize",
    borderRadius: 27,
    fontFamily: "'Montserrat', sans-serif",
  },
};
