import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
// import DashboardLayout from "src/layouts/DashboardLayout";
import LoginLayout from "src/layouts/LoginLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },
  // {
  //   exact: true,
  //   path: "/",
  //   layout: LoginLayout,
  //   component: lazy(() => import("src/views/pages/Home/ComingSoon")),
  // },
  // {
  //   exact: true,
  //   path: "/login",
  //   layout: LoginLayout,
  //   component: lazy(() => import("src/views/pages/Home/Login")),
  // },
  // {
  //   exact: true,
  //   path: "/forgetpassword",
  //   layout: LoginLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/Home/ForgotPassword/index.js")
  //   ),
  // },
  // {
  //   guard: true,
  //   exact: true,
  //   path: "/profile",
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/component/Security")),
  // },
  // {
  //   guard: true,
  //   exact: true,
  //   path: "/balance",
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/component/Balance")),
  // },
  // {
  //   guard: true,
  //   exact: true,
  //   path: "/package",
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/component/Package")),
  // },
  // {
  //   guard: true,
  //   exact: true,
  //   path: "/dashboard",
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Home/Dasboard/Index")),
  // },
  // {
  //   guard: true,
  //   exact: true,
  //   path: "/kyc",
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Home/ContactUs/KYC")),
  // },
  // {
  //   guard: true,
  //   exact: true,
  //   path: "/finance:id",
  //   layout: DashboardLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/Home/Dasboard/Finance/Index")
  //   ),
  // },
  {
    exact: true,
    path: "/blockchain",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Blockchain/Index")),
  },
  {
    exact: true,
    path: "/gaming",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Games/index")),
  },
  {
    exact: true,
    path: "/about",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Aboutus/Index")),
  },

  {
    exact: true,
    path: "/contactUs",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/ContactUs/Index")),
  },
  // {
  //   path: "/register",
  //   layout: LoginLayout,
  //   component: lazy(() => import("src/component/SignUp")),
  // },
  // {
  //   path: "/verify-otp",
  //   layout: LoginLayout,
  //   component: lazy(() => import("src/views/pages/Home/VarifyOtp/index")),
  // },

  // {
  //   path: "/reset-password",
  //   layout: LoginLayout,
  //   component: lazy(() => import("src/views/pages/Home/ResetPassword/index")),
  // },
  {
    path: "/privacy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Privacy")),
  },
  {
    path: "/term-and-condition",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/TermAndCondition")),
  },
  {
    exact: true,
    path: "/social-network",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/SocialNetwok/Index")),
  },

  {
    exact: true,
    path: "/exchange",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Exchange/Index")),
  },
  // {
  //   guard: true,
  //   exact: true,
  //   path: "/my-network:id",
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Home/MyNetwork/MyNetwork")),
  // },

  // {
  //   exact: true,
  //   path: "/support:id",
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Home/Support/Support")),
  // },
  // {
  //   exact: true,
  //   path: "/faq",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/Home/Faq/index")),
  // },
  // {
  //   exact: true,
  //   path: "/details",
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Home/Support/Details")),
  // },
  // {
  //   exact: true,
  //   path: "/roadmap2",
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Home/Roadmap/RoadMapMob")),
  // },
  // {
  //   exact: true,
  //   path: "/varify",
  //   layout: LoginLayout,
  //   component: lazy(() => import("src/views/pages/Home/Varifyemail")),
  // },
  // {
  //   exact: true,
  //   path: "/thank-you",
  //   layout: LoginLayout,
  //   component: lazy(() => import("src/views/pages/Home/ThankYou")),
  // },
  // {
  //   exact: true,
  //   path: "/404",
  //   component: lazy(() => import("src/views/errors/NotFound")),
  // },
  {
    exact: true,
    path: "/",
    component: lazy(() => import("src/views/pages/Home")),
  },
  {
    component: () => <Redirect to="/" />,
  },
  // {
  //   component: () => <Redirect to="/404" />,
  // },
];
